import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EventsListingComponent from "../components/events-listing"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

class Events extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title="Webinars on YouTube ">
        <SEO title="OPTOTAX Webinars" />
        <div className="banner-body">
          <div className="banner-content mt-2">
            <h3 className="caption">WEBINARS</h3>
            <Tabs>
              <TabList>
                <Tab>
                  <h5>Upcoming</h5>
                </Tab>
                <Tab>
                  <h5>Videos</h5>
                </Tab>
              </TabList>

              <TabPanel>
      
      
      
       

 <div className="course-tile-card">
                  <a href="https://zoom.us/webinar/register/6516493085152/WN_-cWsyVwRQtex63g1VKxa5Q" target="_blank">
                    <div className="course-tile-img">
                      <img src={"https://i.ibb.co/XbNfGRS/Whats-App-Image-2022-04-12-at-9-46-05-AM.jpg"} />
                    </div>
                  </a>
                  <div>
                    <h4 className="ml-0">
                      <b> Live Webinar on "Increase your Productivity at Work" (Be Efficient, Not Busy) </b>
                    </h4>
                    <b className="ml-0"> 16th April, 2022. 05:00 PM. </b>
                    <div><a
                      href="https://zoom.us/webinar/register/6516493085152/WN_-cWsyVwRQtex63g1VKxa5Q"
                      target="_blank"
                      className="link-btn"
                    >
                      Register Now
                    </a> </div>
                  </div>
                </div>
     
    
              </TabPanel>
    
              <TabPanel> 
                      
                      <h3 className="caption video-link-header">
                  ‣ [Webinar Series] Unfolding the Future of CA Practice (Adapting to Automation) S-1 | CA. Rohit Golani


    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src = "https://www.youtube.com/embed/9OgX1RMPhzg"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>

<h3 className="caption video-link-header">
                  ‣ [Webinar Series] Unfolding the Future of CA Practice (Adapting to Automation) S-2 | CA. Prem Kasi


    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src = "https://www.youtube.com/embed/6h30_pSQqeE"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>

<h3 className="caption video-link-header">
                  ‣ [Webinar Series] Unfolding the Future of CA Practice (Adapting to Automation) S-3 | CA. Rahul Jindal


    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src = "https://www.youtube.com/embed/840veIYwyMk"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ [Webinar] Global Stock Market Crash (Impact on Indian Economy) | CA. Ajay Singhal


    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src = "https://www.youtube.com/embed/HJxjSqNLj-4"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ [Webinar Series] Decoding GST - Impact on various Sectors S-1 | CA. Aanchal Kapoor


    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src = "https://www.youtube.com/embed/H-fQn8wAXtw"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>

 <h3 className="caption video-link-header">
                  ‣ [Webinar Series] Decoding GST - Impact on various Sectors S-2 | CA. Navya Malhotra


    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src = "https://www.youtube.com/embed/edLKl5lk29s"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>

 <h3 className="caption video-link-header">
                  ‣ [Webinar Series] Decoding GST - Impact on various Sectors S-3 | CA. Vikas Golchha


    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src = "https://www.youtube.com/embed/C9BwanQj5LU"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                       <h3 className="caption video-link-header">
                  ‣ [Webinar Series] Decoding GST - Impact on various Sectors S-4 | CA. Aditya Minal Agarwal


    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src = "https://www.youtube.com/embed/8_H9GOqnjUE"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>

 <h3 className="caption video-link-header">
                  ‣ [Webinar Series] Decoding GST - Impact on various Sectors S-5 | CA. Vineet Rathi


    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src = "https://www.youtube.com/embed/eIs9xixopZY"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>

<h3 className="caption video-link-header">
                  ‣ [Webinar Series] Decoding GST - Impact on various Sectors S-6 | CA. Nihalchand J Jain


    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src = "https://www.youtube.com/embed/jPXJ-JTHE10"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                      
                      
                      <h3 className="caption video-link-header">
                  ‣ Budget 2022 - Road Ahead for Indian Economy | CA. Prerna Peshori | CA. IP Davinder Singh



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/Ul4HB6mbAFQ"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ Gearing up for ROC Annual Filings - Annual Return (2020-2021) | CA. (CS, Adv) Ujjwal Jindal



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/ItN7pbDiw5E"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                       <h3 className="caption video-link-header">
                  ‣ Demystifying Real Estate Transactions | CA. Sangam Aggarwal | S1



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/o9_JPdCz9mA"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                       <h3 className="caption video-link-header">
                  ‣ Demystifying Real Estate Transactions | CA. Ankit Gulgulia | S2



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/-Ta7lEjVPeA"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                       <h3 className="caption video-link-header">
                  ‣ Demystifying Real Estate Transactions | CA. Amarpal | S3



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/1EKKEgc8Irw"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                       <h3 className="caption video-link-header">
                  ‣ Key Considerations while filing Tax Audit Reports | CA. Sachin Sinha



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/w0Uy_SW10kE"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                       <h3 className="caption video-link-header">
                  ‣ 'Stock Market Hidden Treasure' - Understanding of Futures and Options | CA. Ajay Singhal



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/Iv52fG3OlbA"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ [Webinar Series] Future of CA Practice 2.0 | CA. Harsh Jogani



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/uB2YLjyOlJ4"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ [Webinar Series] Future of CA Practice 2.0 | CA. Raksha Agarwal



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/vzAezDj6BvU"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ [Webinar Series] Future of CA Practice 2.0 | CA. Vinodh Kothari S



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/dqaSx2ibfMs"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ Valuation – An Emerging Landscape for CA Profession [Valuation - An Overview]



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/vzAezDj6BvU"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>

 <h3 className="caption video-link-header">
                  ‣ Valuation – An Emerging Landscape for CA Profession [Valuation Opportunities]



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/dqaSx2ibfMs"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>

 <h3 className="caption video-link-header">
                  ‣ Valuation – An Emerging Landscape for CA Profession [Valuation Requirements]



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/Wiv39hrGTco"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ Potential for Growth of CA Practice (Empowering New-Age Start-Ups) - Start-up Funding & Valuation



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/yB1BQ8HbbVM" 
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ Potential for Growth of CA Practice (Empowering New-Age Start-Ups) - Management & Operations



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/FirTMdPyDRA" 
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
<h3 className="caption video-link-header">
                  ‣ Potential for Growth of CA Practice (Empowering New-Age Start-Ups) - Corporate, Taxation & Fiscal Laws




    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/6psm2OauIc0" 
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
<h3 className="caption video-link-header">
                  ‣ Overview of Insolvency and Bankruptcy Code (Tips to Clear Exams) | CA. R.K. Gupta



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/U_8Nv95oMvg"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
 <h3 className="caption video-link-header">
                  ‣ Tax Audit under Income Tax Act | CA. Rajat Powar | S1



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/02rcK3F7NjA" 
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>

<h3 className="caption video-link-header">
                  ‣ Tax Audit under Income Tax Act | CA. Manoj Gupta | S2



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/-1tBk8NLxgE" 
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>

<h3 className="caption video-link-header">
                  ‣ Tax Audit under Income Tax Act | CA. Sachin Sinha | S3



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/MclgQ7H5uaA" 
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      


<h3 className="caption video-link-header">
                  ‣ Webinar on Valuation Requirement under Income Tax, Company Law & FEMA by CA. Ankit Goel



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/p7e11jrTSXc"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ Certificate Course on Income Tax Returns (Providing Value Addition to Clients) S-1



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/kjKJCKzPkJU" 
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>



<h3 className="caption video-link-header">
                  ‣ Certificate Course on Income Tax Returns (Providing Value Addition to Clients) S-2



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/22hQaWMEyL4" 
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>

<h3 className="caption video-link-header">
                  ‣ Certificate Course on Income Tax Returns (Providing Value Addition to Clients) S-3



    </h3>
      
     <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/9uB2h2FH3Ds"
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ Optotax Talk Show - Mulaqat with Mentors (Episode - 1) by CA. Jay Chhaira


    </h3>
      
     <iframe 
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/DSxgeZ-vjl4" 
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>

<h3 className="caption video-link-header">
                  ‣ Optotax Talk Show - Mulaqat with Mentors (Episode - 2) by CA. (Dr.) Arpit Haldia



    </h3>
      
    <iframe
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/atWkuJq_DQM" 
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>





                      
                       <h3 className="caption video-link-header">
                  ‣ Income Tax Portal - The NEW Avatar by CA(CS). Ujjwal Jindal

    </h3>
      
     <iframe 
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/NbCoxI1Rhj4" 
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>

                      <h3 className="caption video-link-header">
                  ‣ Central & State Govt. MSME Subsidies & Professional Opportunities for CAs by CA. G.B. Modi

    </h3>
      
     <iframe 
className="yt-pl-video"
width="560" 
height="315" 
src="https://www.youtube.com/embed/wzxRAiytS9k" 
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ New Age Investing Opportunities for Chartered Accountants by CA. Aankit Kumar Jain
    </h3>
      
     <iframe 
className="yt-pl-video" 
width="560" 
height="315" 
src="https://www.youtube.com/embed/CTSHoRJsI5I" 
title="YouTube video player" 
frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ Revival and Scope of CA Practice Post Pandemic by CA. Navya Malhotra
    </h3>
      
      <iframe 
      className="yt-pl-video" 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/vb3g7m1qMIM"
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>
      
      <h3 className="caption video-link-header">
                  ‣ CA Day Celebration & 4 Years of GST
    </h3>
      
      <iframe 
      className="yt-pl-video" 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/ZDMF4ly6DWg" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>
      
       <h3 className="caption video-link-header">
                  ‣ Burning Issues in Input Tax Credit - CA. Vineet Rathi [Guest of Honor - CA. Anuj Goyal]
    </h3>
      
      <iframe 
      className="yt-pl-video" 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/AmfInE-EaDw" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>
      
      <h3 className="caption video-link-header">
                  ‣ Critical Analysis of the Arrest Provisions in GST - CA. Navjot Singh

    </h3>
<iframe 
  className="yt-pl-video" 
  width="560" 
  height="315" 
  src="https://www.youtube.com/embed/c83dzgv2yOs" 
  title="YouTube video player" 
  frameborder="0" 
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
  allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ Blockchain and Cryptocurrency - CA. Aishwary Gupta

    </h3>
 <iframe 
    className="yt-pl-video" 
    width="560" height="315" 
    src="https://www.youtube.com/embed/IQVquYgnsO8" 
    title="YouTube video player" 
    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ Latest Changes in Income Tax Portal - CA. Pratham Bhatia

    </h3>
  <iframe 
   className="yt-pl-video" 
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/Q60WULwN0_8" 
    title="YouTube video player" 
    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ Latest Updates in GST (Including 43rd GST Council Recommendations) - CA. Ramya. C

    </h3>
  <iframe 
    className="yt-pl-video"
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/2Yr1qgeSLOM" 
    title="YouTube video player" 
    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
                      
                       <h3 className="caption video-link-header">
                  ‣ Latest Amendments in TDS/TCS Provisions under Income Tax Act - CA. Sangam Aggarwal

    </h3>
   <iframe 
    className="yt-pl-video"
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/YsG1ayqLATo" 
    title="YouTube video player" 
    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
      
      <h3 className="caption video-link-header">
                  ‣ Critical Analysis on Inspection, Search & Seizure in GST - CA. Navjot Singh

    </h3>
    <iframe 
     className="yt-pl-video"
     width="560" 
     height="315" 
      src="https://www.youtube.com/embed/_AtxuNTPgK8" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>
      
       <h3 className="caption video-link-header">
                  ‣ Clause by Clause Analysis on CARO 2020 vs CARO 2016 - CA. Sumit Garg

    </h3>
    <iframe
    className="yt-pl-video"
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/ggishoT65wM" 
    title="YouTube video player" 
    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
      
       <h3 className="caption video-link-header">
                  ‣ Latest Amendments in Finance Act, 2021 - CA. Rohit Kapoor

    </h3>
    <iframe 
    className="yt-pl-video"
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/GO5ubQCrKl8" 
    title="YouTube video player" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
      
      
      
       <h3 className="caption video-link-header">
                  ‣ Practical issues in Input Tax Credit (ITC) - CA. Pitam Goel (Indirect Tax Expert)

    </h3>
    <iframe 
     className="yt-pl-video"
     width="560"
     height="315" 
     src="https://www.youtube.com/embed/MurXQexi1Uo"
     title="YouTube video player"
     frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
     allowfullscreen></iframe>
                      
                       <h3 className="caption video-link-header">
                  ‣ All about E-Invoicing under GST by CA. Vishal Goyal

    </h3>
    <iframe 
    className="yt-pl-video"
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/OuiNws3eVYg" 
    title="YouTube video player" 
    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
                      
                      <h3 className="caption video-link-header">
                  ‣ Webinar on Effect of GST Transactions on Financial Statements for F.Y 2020-21 by CA. Vikas Golchha

    </h3>
    <iframe 
    className="yt-pl-video"
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/kNY9mqRrARI" 
    title="YouTube video player" 
    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
    
      <h3 className="caption video-link-header">
                  ‣  Webinar on Issues in Input Tax Credit (ITC) by CA. Rajat Gurnani


    </h3>
      <iframe 
      className="yt-pl-video"
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/0R5lXVt-coE" 
      title="YouTube video player" 
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>
      
      <h3 className="caption video-link-header">
                  ‣  How to Effectively Manage your CA Practice in this Pandemic by CA. Navya Malhotra


    </h3>
      <iframe 
      className="yt-pl-video"
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/U-uYVBpK-jU" 
      title="YouTube video player" 
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>
      
      
       <h3 className="caption video-link-header">
         
                  ‣  CA Practice in Digital Era by CA Narsimhan Elangovan by CA. Ram Bajaj 


    </h3>
      <iframe 
      className="yt-pl-video"
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/YYV_pMkjVNw" 
      title="YouTube video player" 
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>
      
      <h3 className="caption video-link-header">
     
  
                  ‣  Litigation under GST (An Opportunity for CAs) – by CA. Ram Bajaj [Guest of Honor - CA. Suhel Goel]


    </h3>
      
      <iframe 
      className="yt-pl-video"
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/ZGBEX06SExQ" 
      title="YouTube video player" 
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>
       <h3 className="caption video-link-header">
     
  
                 ‣  Webinar on Denial of IGST Refund to Advanced Authorisation Holders by CA. Navjot Singh


    </h3>
      
      <iframe 
      className="yt-pl-video"
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/AYJKl_0SuBo" 
      title="YouTube video player" 
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>
    
      
      <h3 className="caption video-link-header">
     
  
                  ‣  Webinar on Reverse Charge Mechanism under GST by CA. Ramya. C

    </h3>
      
     <iframe 
      className="yt-pl-video"
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/ic6Xj5rdLJE" 
      title="YouTube video player" 
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>
      
       <h3 className="caption video-link-header">
  
                  ‣  Webinar on Bank Audit by CA. Sumit Garg
    </h3>
      
      <iframe 
    className="yt-pl-video"
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/coNeFrd6H_8" 
    title="YouTube video player" 
    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
      
       <h3 className="caption video-link-header">
  
                  ‣  Issues in Input Tax Credit by CA. Rajat Gurnani (Organized by Telangana and Andhra Pradesh Tax Bar Association)
    </h3>
      
      <iframe 
      className="yt-pl-video"
      width="560" 
      height="315" src="https://www.youtube.com/embed/zollVvV788w" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>
      
      <h3 className="caption video-link-header">
  
                  ‣  Ask The Expert Episode 1 – Q&A on New GST Return Mechanism (QRMP Scheme) – by CA. Rajat Gurnani
    </h3>
      
      <iframe 
      className="yt-pl-video"
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/a9x0W0BW68M" 
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>
      
      <h3 className="caption video-link-header">
  
                  ‣  Webinar on Interplay of Ind-AS by CA. Kartik Jindal
                </h3>
      
      <iframe 
      className="yt-pl-video"
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/8T6_YRiLbKU" 
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>
                      <h3 className="caption video-link-header">
  
                  ‣  Online Interactive Discussion on GST Proposals in Union Budget 2021 with CA. Aditya Singhania

                </h3>
<iframe 
    className="yt-pl-video" 
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/bSVPXp2YARQ"
    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
                      <h3 className="caption video-link-header">
  
                  ‣  Webinar on Intricacies in Input Tax Credit by CA. Yogesh Gaba
                </h3>
<iframe 
    className="yt-pl-video" 
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/3Jg3GHjmTao" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
                      <h3 className="caption video-link-header">
  
                  ‣  Webinar on Latest Updates under GST (Including Proposals in Budget 2021) - CA. Ramya C [தமிழ்]
                </h3>
<iframe 
    className="yt-pl-video" 
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/D5dHWzMRxbE" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
                      <h3 className="caption video-link-header">
  
                  ‣  Webinar on Vouching in Tally Prime by Tally Sekar [தமிழ்]
                </h3>
<iframe  
    className="yt-pl-video" 
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/0aVXlWr7P6A" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
                       <h3 className="caption video-link-header">
  
                  ‣  Webinar on Budget Analysis (Direct Tax Provisions) - CA. Pankaj Saraogi
                </h3>
<iframe  
    className="yt-pl-video" 
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/NMV32qOFK0A" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
        <h3 className="caption video-link-header">
  
                  ‣  Webinar on Latest Updates in GST and GSTN Portal – CA. Kashish Gupta
                </h3>
<iframe  
    className="yt-pl-video" 
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/V8UbJc58Xkk" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
    
      <h3 className="caption video-link-header">
  
                  ‣  Webinar on How to Reply Notices under Income Tax – CA. Manoj Lamba [हिंदी] 
                </h3>
<iframe 
    className="yt-pl-video" 
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/niWsr84GVBU" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
       
      
      <h3 className="caption video-link-header">
  
                  ‣  New GST Returns Mechanism (QRMP Scheme) by CA. Ramya C [தமிழ்] 
                </h3>
                        <iframe 
    className="yt-pl-video" 
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/4vxEFXwkgTU" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
       <h3 className="caption video-link-header">
                  ‣  Webinar on Issues in ITC by CA. Nihalchand J Jain
                </h3>
                        <iframe 
      className="yt-pl-video" 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/YLspzK2MHQs"
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>
                      <h3 className="caption video-link-header">
                  ‣  Latest Updates in GST by CA. Navya Malhotra
                </h3>
                        <iframe 
    className="yt-pl-video" 
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/rOmRxG5K0xQ" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen
      ></iframe>
                        <h3 className="caption video-link-header">
                  ‣  Webinar on Latest Updates in GST by CA. Archana Jain
                </h3>
                        <iframe
    className="yt-pl-video"
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/AXA3rvHbkgg" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen
      ></iframe>
                <h3 className="caption video-link-header">
                  ‣  Webinar on ITC Reconciliation - GSTR-2A Vs. GSTR-2B by CA.
                  Aanchal Kapoor
                </h3>
                <iframe
      className="yt-pl-video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/d3OhynUiUQw"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <h3 className="caption video-link-header">
                  ‣  Webinar by CA. (Dr.) Arpit Haldia on: Burning Issues in GST
                </h3>
                <iframe
                  className="yt-pl-video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/bZG8bD7NTGo"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>

                <h3 className="caption video-link-header">
                  ‣  Webinar by CA. (Dr.) Arpit Haldia on: How to handle Issues
                  of Input Tax Credit (ITC)
                </h3>
                <iframe
                  className="yt-pl-video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/6XP5sBf3LAI"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <h3 className="caption video-link-header">
                  ‣  இன்புட் டாக்ஸ் கிரெடிட் (பல்வேறு நடைமுறை சிக்கல்களுடன்)
                </h3>
                <iframe
                  className="yt-pl-video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/LByLtzBzdMc"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <h3 className="caption video-link-header">
                  ‣  Webinar on GSTR-9 & GSTR-9C by CA. Aanchal Kapoor
                </h3>
                <iframe
                  className="yt-pl-video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/E1wrtvkRwuM"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <h3 className="caption video-link-header">
                  ‣  Webinar on Search and Seizure (Sec 67)
                </h3>
                <iframe
                  className="yt-pl-video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/UJ25LYt16Ug"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <h3 className="caption video-link-header">
                  ‣  Grand Webinar on Ease of GST Compliance for Tax
                  Professionals
                </h3>
                <iframe
                  className="yt-pl-video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Jik4OdZo79c"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <h3 className="caption video-link-header">
                  ‣  Webinar on Blocked ITC - Sec 17(5)
                </h3>
                <iframe
                  className="yt-pl-video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/nZBUxJmcCik"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <h3 className="caption video-link-header">
                  ‣  Webinar on GST Audit (Sec 65, 66 & 44)
                </h3>
                <iframe
                  className="yt-pl-video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/JmyGnzDTtxo"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <h3 className="caption video-link-header">
                  ‣  Webinar on RCM by CA. Bimal Jain & CA. Rajat Gurnani
                </h3>
                <iframe
                  className="yt-pl-video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/KY76zr07de4"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <h3 className="caption video-link-header">
                  ‣  Latest Updates in GST by CA. Rajat Gurnani (updated as on
                  30th July 2020)
                </h3>
                <iframe
                  className="yt-pl-video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/L0u3WxnC_uM"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <h3 className="caption video-link-header">
                  ‣  TNGSTPA & Optotax - தமிழ் வெபினார் - பிளேஸ் ஆஃப் சப்ளை -
                  CA ரம்யா சி.
                </h3>
                <iframe
                  className="yt-pl-video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/QG6hquB0OuI"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <h3 className="caption video-link-header">
                  ‣  Webinar on Recent Amendments in GST
                </h3>
                <iframe
                  className="yt-pl-video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/bmp1FFFvAko"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <h3 className="caption video-link-header">
                  ‣  Webinar on Mixed and Composite Supply by CA. Bimal Jain
                </h3>
                <iframe
                  className="yt-pl-video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/lWEJV29f0es"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Events

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
